import { useEffect, useState } from "react";

export function useIsDesktop() {
  const isWindowDefined = typeof window !== "undefined"; //Improved variable name

  if (!isWindowDefined) {
    //Improved if block using clearly named variable
    return { isDesktop: false };
  }

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const windowResizeHandler = () => {
      // Removed unused 'e' parameter
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener("resize", windowResizeHandler);

    return () => {
      window.removeEventListener("resize", windowResizeHandler);
    };
  }, []);

  return { isDesktop };
}
