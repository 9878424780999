"use client";

import React, { forwardRef } from "react";
import { motion, HTMLMotionProps } from "framer-motion";

interface AnimatedProps extends HTMLMotionProps<"div"> {
  children: React.ReactNode;
  direction?: "left" | "right" | "top" | "bottom";
  delay?: number;
  incorporate?: boolean;
  className?: string;
  layout?: boolean;
}

const Animated = forwardRef<HTMLDivElement, AnimatedProps>(
  (
    {
      children,
      incorporate = false,
      direction = "top",
      delay = 0,
      className,
      layout,
      ...props
    },
    ref
  ) => {
    const variants = {
      hidden: {
        opacity: 0,
        x: direction === "left" ? -25 : direction === "right" ? 25 : 0,
        y: direction === "top" ? -25 : direction === "bottom" ? 25 : 0,
      },
      visible: {
        opacity: 1,
        x: 0,
        y: 0,
        transition: {
          type: "spring",
          mass: 0.1,
          delay: delay,
        },
      },
      exit: {
        opacity: 0,
        x: direction === "left" ? -25 : direction === "right" ? 25 : 0,
        y: direction === "top" ? -25 : direction === "bottom" ? 25 : 0,
      },
    };

    if (!incorporate) {
      return (
        <motion.div
          ref={ref}
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={variants}
          className={className}
          layout={layout}
          {...props}
        >
          {children}
        </motion.div>
      );
    }

    // For incorporated components
    const MotionComponent = motion(
      forwardRef((props: any, innerRef: React.Ref<any>) => (
        <div ref={innerRef} {...props}>
          {children}
        </div>
      ))
    );

    return (
      <MotionComponent
        ref={ref}
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={variants}
        className={className}
        layout={layout}
        {...props}
      />
    );
  }
);

Animated.displayName = "Animated";

export default Animated;
