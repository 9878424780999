{
  /* Move the Heart button inside the flex container */
}
{
  /* <Button
            aria-label="Favoritar"
            variant="outline"
            size="icon"
            className="absolute top-3 left-3 p-2 bg-white dark:bg-gray-700 rounded-full hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors duration-200 shadow-md"
          >
            <HeartIcon className="w-5 h-5 text-red-500" />
          </Button> */
}

import React from "react";
import { Card } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import Link from "next/link";
import MobileCardImage from "./MobileCardImage";


interface Property {
  objectID: string;
  ref?: string;
  property_type?: string;
  neighborhood?: string;
  sale_value?: number;
  rent_value?: number;
  bedrooms?: number;
  suites?: number;
  parking_spaces?: number;
  bathrooms?: number;
  total_area?: number;
  usable_area?: number;
  imagekit?: string;
  title?: string;
  type?: string;
  is_for_sale?: boolean;
  is_for_rent?: boolean;
  photos?: {
    ref: string;
    src: {
      aws: string;
      imagekit: string;
    };
  }[];
}

interface MobileCardProps {
  hit: Property;
  setIsSearchExpanded?: (value: boolean) => void;
  alternativePhotos?: boolean;
}

const MobileCard: React.FC<MobileCardProps> = ({
  hit,
  setIsSearchExpanded,
  alternativePhotos,
}) => {
  // Determine the image URL and blur placeholder
  const imageUrl =
    alternativePhotos && hit.photos && hit.photos.length > 0
      ? hit.photos[0]?.src?.imagekit
      : hit.imagekit || "";

  return (
    <Link
      href={`/imovel/${hit.ref?.startsWith('MB') ? hit.ref : `MB${hit.ref}`}`}
      onClick={() => setIsSearchExpanded && setIsSearchExpanded(false)}
      className="focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
      <Card className="transition-all w-full h-56 overflow-hidden rounded-none hover:shadow-xl hover:-translate-y-1 bg-white dark:bg-gray-800 flex cursor-pointer">
        <div className="grid grid-cols-[35%_1fr]">
          <div className="relative w-full">
          <MobileCardImage
              imageUrl={imageUrl}
              title={hit?.title}
              lazy={true}
            />
            <Badge className="absolute left-1/2 -translate-x-1/2 bottom-3 font-medium bg-background border border-border text-foreground">
              {hit.ref || "Referência não disponível"}
            </Badge>
          </div>

          <div className="flex-1 p-2 flex flex-col justify-between">
            <div className="flex items-center justify-between">
              <h2 className="text-md font-bold mb-0 text-navy-900 dark:text-white line-clamp-2">
                {hit.title || "Título indisponível"}
              </h2>
              {/* <Badge className="px-1 py-1 text-sm font-semibold">
                {hit.type || "Tipo não disponível"}
              </Badge> */}
            </div>

            {/* Rest of the card content */}
            <div className="flex flex-wrap justify-between px-1 font-medium gap-2 my-2 text-[0.75rem] text-navy-700 dark:text-gray-300 gap-x-4 mt-1">
              {hit.bedrooms ? (
                <div className="flex flex-col items-center">
                  <p className="text-muted-foreground">Dorms</p>
                  <span className="font-bold">{hit.bedrooms ?? 0}</span>
                </div>
              ) : null}
              {hit.suites ? (
                <div className="flex flex-col items-center">
                  <p className="text-muted-foreground">Suítes</p>
                  <span className="font-bold">{hit.suites ?? 0}</span>
                </div>
              ) : null}
              {hit.parking_spaces ? (
                <div className="flex flex-col items-center">
                  <p className="text-muted-foreground">Vagas</p>
                  <span className="font-bold">{hit.parking_spaces ?? 0}</span>
                </div>
              ) : null}
              {hit.usable_area ? (
                <div className="flex flex-col items-center">
                  <p className="text-muted-foreground">Área Útil</p>
                  <span className="font-bold">{hit.usable_area ?? 0}m²</span>
                </div>
              ) : null}
              {hit.total_area ? (
                <div className="flex flex-col items-center">
                  <p className="text-muted-foreground">Área Total</p>
                  <span className="font-bold">
                    {hit.total_area || hit.usable_area || 0}m²
                  </span>
                </div>
              ) : null}
            </div>

            {/* Sale/Rent information */}
            {hit.is_for_sale ? (
              <span className="flex flex-row items-center gap-2 text-sm">
                <p className="text-navy-900 dark:text-white">Venda</p>
                {hit.sale_value !== undefined && (
                  <strong className="text--primary">
                    {hit.sale_value.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </strong>
                )}
              </span>
            ) : null}
            {hit.is_for_rent ? (
              <span className="flex flex-row items-center gap-2 text-sm">
                <p className="text-navy-900 dark:text-white">Aluguel</p>
                <strong className="text--primary">
                  {hit.rent_value?.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}{" "}
                  / mês
                </strong>
              </span>
            ) : null}
          </div>
        </div>
      </Card>
    </Link>
  );
};

export default MobileCard;
