"use client";

import React, { createContext, useState, useContext } from "react";

interface LayoutContextType {
  isSearchExpanded: boolean;
  setIsSearchExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

export const LayoutProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  return (
    <LayoutContext.Provider value={{ isSearchExpanded, setIsSearchExpanded }}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error("useLayout must be used within a LayoutProvider");
  }
  return context;
};
