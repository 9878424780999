"use client";
import { useState } from "react";
import { motion } from "framer-motion";
import { memo } from "react";
import { useMediaQuery } from "react-responsive";
import { Button } from "../ui/button";
import Link from "next/link";
import { BookCheck, ChevronRight, Sparkles } from "lucide-react";
import Animated from "../animated";
import Image from "next/image";

// Interface definitions for the hero images and props

export interface HeroImages {
  desktop: string;
  mobile: string;
}

interface HeroSectionProps {
  heroImages: HeroImages;
  title: string;
  subtitle?: string;
  buttonText?: string;
  buttonLink?: string;
  searchButtons?: boolean;
  blurDataURL?: string;
}

export const HeroSection = memo(function HeroSection({
  heroImages,
  title,
  subtitle,
  buttonText,
  buttonLink,
  searchButtons,
  blurDataURL,
}: HeroSectionProps) {
  const [hasError, setHasError] = useState(false);

  const handleImageError = () => {
    setHasError(true);
    console.error("Failed to load hero image");
  };

  if (hasError) {
    return (
      <div className="absolute inset-0 bg-gray-800 flex items-center justify-center">
        <div className="text-center">
          <p className="text-gray-300 mb-4">
            Não foi possível carregar a imagem
          </p>
          <button
            type="button"
            onClick={() => setHasError(false)}
            className="text-primary hover:text-emerald-600 focus:outline-none focus:ring-2 focus:ring-emerald-500"
            aria-label="Tentar novamente"
          >
            Tentar novamente
          </button>
        </div>
      </div>
    );
  }

  // Media queries to detect screen size
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <>
      <section className="relative h-[80vh] min-h-[550px] w-full flex items-center justify-center text-center">
        {/* Background Gradient for visual effect */}
        <div className="absolute inset-0 bg-gradient-to-b from-slate-950/50 to-slate-950/30 z-10" />

        {/* Hero Image */}
        <div className="absolute inset-0">
          {heroImages && (
            <Image
              src={isMobile ? heroImages.mobile : heroImages.desktop}
              alt="Hero Background"
              className="object-cover"
              fill
              priority
              quality={75}
              fetchPriority="high"
              sizes="100vw"
              placeholder="blur"
              blurDataURL={blurDataURL || "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDABQODxIPDRQSEBIXFRQdHx4eHRoaHSQtJSEkMjU1LCkwLi4vMEQ6Ojw+PS47RUFFTVNTVVlZXV5fZWZmYmFgW1r/2wBDARUXFx4aHh4lHSUtLVFKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkr/wAARCAAIAAoDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAb/xAAUEAEAAAAAAAAAAAAAAAAAAAAA/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAX/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwCdABmX/9k="}
              onError={handleImageError}
            />
          )}
        </div>

        <div className="relative z-20 max-w-3xl mx-auto px-6">
          <motion.h1
            className="text-4xl md:text-6xl font-bold leading-medium mb-8 text-gray-200"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            {title}
          </motion.h1>

          {subtitle && (
            <motion.p
              className="text-lg md:text-2xl text-gray-300 mb-10"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
            >
              {subtitle}
            </motion.p>
          )}

          <Animated>
            <span className="flex flex-col md:flex-row gap-4 items-center justify-center">
              {searchButtons ? (
                <>
                  <Button
                    aria-label={buttonText}
                    size="lg"
                    className="px-8 py-6 font-semibold text-base before:ease relative h-12 overflow-hidden border border-transparent hover:border-border bg-primary hover:bg-primary text-primary-foreground shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-700 hover:shadow-primary hover:before:-translate-x-64"
                    variant={"secondary"}
                    onClick={() =>
                      document.getElementById("smart-search-input")?.focus()
                    }
                  >
                    <Sparkles />
                    <span>Busca Inteligente</span>
                    <ChevronRight className="ml-2 -mr-1 h-6 w-6" />
                  </Button>
                  <Link href={"/traditional"}>
                    <Button
                      aria-label={buttonText}
                      size="lg"
                      className="px-8 font-semibold text-base h-12 border border-transparent"
                      variant={"secondary"}
                    >
                      <BookCheck />
                      <span className="drop-shadow-xl">Busca tradicional</span>
                      <ChevronRight className="ml-2 -mr-1 h-6 w-6" />
                    </Button>
                  </Link>
                </>
              ) : null}

              {buttonText && buttonLink ? (
                <>
                  <Button
                    aria-label={buttonText}
                    size="lg"
                    className="px-8 font-semibold text-base before:ease relative h-12 overflow-hidden border border-transparent hover:border-border bg-primary hover:bg-primary text-primary-foreground shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-700 hover:shadow-primary hover:before:-translate-x-64"
                    variant={"secondary"}
                    onClick={() =>
                      document.getElementById("smart-search-input")?.focus()
                    }
                  >
                    <Sparkles />
                    <span>Encontre um imóvel</span>
                    <ChevronRight className="ml-2 -mr-1 h-6 w-6" />
                  </Button>
                  <Link href={buttonLink}>
                    <Button
                      aria-label={buttonText}
                      size="lg"
                      className="inline-flex items-center px-8 py-6 text-base font-semibold text-card-foreground bg-card shadow-lg rounded-none transition-colors"
                    >
                      {buttonText}
                      <ChevronRight className="ml-2 -mr-1 h-6 w-6" />
                    </Button>
                  </Link>
                </>
              ) : null}
            </span>
          </Animated>
        </div>
      </section>
    </>
  );
});
