// DesktopCard.tsx
import React from "react";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Heart as HeartIcon } from "lucide-react";
import Link from "next/link";
import { cn } from "@/lib/utils";
import Image from "next/image";

interface Property {
  objectID: string;
  ref?: string;
  property_type?: string;
  neighborhood?: string;
  sale_value?: number;
  rent_value?: number;
  bedrooms?: number;
  suites?: number;
  bathrooms?: number;
  parking_spaces?: number;
  total_area?: number;
  usable_area?: number;
  imagekit?: string;
  title?: string;
  type?: string;
  is_for_sale?: boolean;
  is_for_rent?: boolean;
  photos?: {
    ref: string;
    src: {
      aws: string;
      imagekit: string;
    };
  }[];
}

interface DesktopCardProps {
  hit: Property;
  setIsSearchExpanded?: (value: boolean) => void;
  alternativePhotos?: boolean;
  className?: string;
  fullWidth?: boolean;
}

const DesktopCard: React.FC<DesktopCardProps> = ({
  hit,
  setIsSearchExpanded,
  alternativePhotos,
  className,
  fullWidth = false,
}) => {
  const formatCurrency = (value: number) => {
    return value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  // Moved optimizeImageUrl to a utility file for reuse (optional)
  const optimizeImageUrl = (url: string, width: number) => {
    if (!url) return "/placeholder.jpg"; // Fallback to placeholder image
    const baseUrl = url.split("?")[0];
    return `${baseUrl}?tr=f-auto,q-auto,w-${width}`;
  };

  // Determine the image URL
  const imageUrl =
    alternativePhotos && hit.photos && hit.photos.length > 0
      ? hit.photos?.sort((a: any, b: any) => a.seq - b.seq)[0]?.src?.imagekit
      : hit.imagekit || "/placeholder.jpg";

  return (
    <>
      <Link
        href={`/imovel/${hit.ref?.startsWith("MB") ? hit.ref : `MB${hit.ref}`}`}
        onClick={() => setIsSearchExpanded && setIsSearchExpanded(false)}
        className="focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <Card
          className={cn(
            "transition-all overflow-hidden border-none shadow-md dark:border-slate-950 rounded-none hover:shadow-xl hover:-translate-y-1 flex flex-col cursor-pointer",
            className,
            {
              "w-full": fullWidth,
              "w-[410px] h-[410px]": !fullWidth,
            }
          )}
        >
          <div className="relative">
            <Image
              src={optimizeImageUrl(imageUrl, 600)}
              alt={hit.title || "Imagem de propriedade"}
              width={600}
              height={400}
              sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 500px"
              placeholder="blur"
              blurDataURL={optimizeImageUrl(imageUrl, 10)}
              className="w-full h-52 object-cover"
              priority={!alternativePhotos}
            />

            <div className="absolute inset-x-0 top-0 p-3 flex justify-between items-start">
              <Badge className="bg-white/90 dark:bg-slate-900/90 text-foreground backdrop-blur-sm px-3 py-1 rounded-none font-medium text-sm border-none">
                {hit.neighborhood || "Bairro não disponível"}
              </Badge>

              <Button
                aria-label="Favoritar"
                variant="outline"
                size="icon"
                className="bg-white/90 dark:bg-slate-900/90 backdrop-blur-sm rounded-full hover:bg-white dark:hover:bg-slate-800 transition-colors duration-200 border-none shadow-sm"
              >
                <HeartIcon className="w-4 h-4 text-red-500" />
              </Button>
            </div>

            <Badge
              className="absolute left-3 -bottom-3 rounded-none font-medium text-xs bg-primary text-primary-foreground shadow-md px-3 py-1"
              variant="secondary"
            >
              {hit.ref || "Sem referência"}
            </Badge>
          </div>

          <CardContent className="mt-6 flex-1 space-y-2">
            <h2 className="text-md font-semibold text-foreground line-clamp-2 min-h-[3rem]">
              {hit.title || "Título indisponível"}
            </h2>

            <div className="w-full flex justify-between items-end">
              {hit.is_for_sale && hit.sale_value ? (
                <span className="flex flex-col gap-1 text-sm">
                  <p className="text-foreground text-xs">Venda</p>
                  <strong className="text-foreground">
                    {formatCurrency(hit.sale_value)}
                  </strong>
                </span>
              ) : null}

              {hit.is_for_rent ? (
                <span className="flex flex-col gap-1 text-sm">
                  <p className="text-foreground text-xs">Locação</p>
                  <strong className="text-foreground">
                    {formatCurrency(hit.rent_value ?? 0)}
                  </strong>
                </span>
              ) : null}
            </div>

            {/* <div className="grid grid-cols-4 gap-2 text-sm">
            {hit.bedrooms ? (
              <div className="flex flex-col items-center p-2 rounded-none bg-muted/50">
                <span className="text-xs text-muted-foreground">
                  Dorms
                </span>
                <span className="font-semibold text-foreground">
                  {hit.bedrooms ?? ""}
                </span>
              </div>
            ) : null}

            {hit.suites ? (
              <div className="flex flex-col items-center p-2 rounded-none bg-muted/50">
                <span className="text-xs text-muted-foreground">
                  Suítes
                </span>
                <span className="font-semibold text-foreground">
                  {hit.suites ?? ""}
                </span>
              </div>
            ) : null}

            {hit.parking_spaces ? (
              <div className="flex flex-col items-center p-2 rounded-none bg-muted/50">
                <span className="text-xs text-muted-foreground">
                  Vagas
                </span>
                <span className="font-semibold text-foreground">
                  {hit.parking_spaces ?? ""}
                </span>
              </div>
            ) : null}

            {hit.usable_area ? (
              <div className="flex flex-col items-center p-2 rounded-none bg-muted/50">
                <span className="text-xs text-muted-foreground">
                  Área Útil
                </span>
                <span className="font-semibold text-foreground">
                  {hit.usable_area
                    ? `${hit.usable_area.toLocaleString("pt-BR")} m²`
                    : ""}
                </span>
              </div>
            ) : null}

            {hit.total_area ? (
              <div className="flex flex-col items-center p-2 rounded-none bg-muted/50">
                <span className="text-xs text-muted-foreground">
                  Área Total
                </span>
                <span className="font-semibold text-foreground">
                  {hit.total_area
                    ? `${hit.total_area.toLocaleString("pt-BR")} m²`
                    : ""}
                </span>
              </div>
            ) : null}
          </div> */}
          </CardContent>

          <CardFooter className="pt-0 mt-[-0.5rem]">
            {/* <div className="w-full flex justify-between items-end">
            {hit.is_for_sale && hit.sale_value ? (
              <span className="flex flex-col  gap-2 text-sm">
                 <p className="text-foreground text-xs">Venda</p>
                 <strong className="text-foreground">
                  {formatCurrency(hit.sale_value)}
                </strong>
                </span>
            ) : null}
            
          {hit.is_for_rent ? (
            <span className="flex flex-col  gap-2 text-sm">
              <p className="text-foreground text-xs">Locação</p>
              <strong className="text-foreground">
                {formatCurrency(hit.rent_value ?? 0)}
              </strong>
            </span>
            ) : null}
          </div> */}
            <div className="w-full justify-start flex flex-wrap flex-auto gap-0 text-sm items-center">
              {hit.bedrooms ? (
                <div className="flex flex-col items-center p-2 rounded-none">
                  <span className="text-xs text-muted-foreground">Dorms</span>
                  <span className="font-semibold text-foreground">
                    {hit.bedrooms ?? ""}
                  </span>
                </div>
              ) : null}

              {hit.suites ? (
                <div className="flex flex-col items-center p-2 rounded-none">
                  <span className="text-xs text-muted-foreground">Suítes</span>
                  <span className="font-semibold text-foreground">
                    {hit.suites ?? ""}
                  </span>
                </div>
              ) : null}

              {hit.parking_spaces ? (
                <div className="flex flex-col items-center p-2 rounded-none">
                  <span className="text-xs text-muted-foreground">Vagas</span>
                  <span className="font-semibold text-foreground">
                    {hit.parking_spaces ?? ""}
                  </span>
                </div>
              ) : null}

              {hit.usable_area ? (
                <div className="flex flex-col items-center p-2 rounded-none">
                  <span className="text-xs text-muted-foreground">
                    Área Útil
                  </span>
                  <span className="font-semibold text-foreground">
                    {hit.usable_area
                      ? `${hit.usable_area.toLocaleString("pt-BR")}m²`
                      : ""}
                  </span>
                </div>
              ) : null}

              {hit.total_area ? (
                <div className="flex flex-col items-center p-2 rounded-none">
                  <span className="text-xs text-muted-foreground">
                    Área Total
                  </span>
                  <span className="font-semibold text-foreground">
                    {hit.total_area
                      ? `${hit.total_area.toLocaleString("pt-BR")}m²`
                      : ""}
                  </span>
                </div>
              ) : null}
            </div>
          </CardFooter>
        </Card>
      </Link>
    </>
  );
};

export default React.memo(DesktopCard);
