"use client";
import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import { useForm, ValidationError } from "@formspree/react";

const countryCodes = [
  { value: "+55", label: "BR", placeholder: "+55 (99) 99999-9999" },
  { value: "+1", label: "US", placeholder: "+1 (999) 999-9999" },
  { value: "+351", label: "PT", placeholder: "+351 999 999 999" },
];

export default function Form() {
  const [state, handleSubmit] = useForm(
    process.env.NEXT_PUBLIC_FORMSPREE_ID_LP
  );
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    countryCodes[0]
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pageUrl, setPageUrl] = useState("");

  useEffect(() => {
    setPageUrl(window.location.href);
  }, []);

  const handleCountryCodeChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedCountryCode(selectedOption);
      setPhoneNumber("");
    }
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const getMask = (countryCode) => {
    switch (countryCode) {
      case "+1":
        return "+1 (999) 999-9999";
      case "+351":
        return "+351 999 999 999";
      case "+55":
        return "+55 (99) 99999-9999";
      default:
        return "99999-9999";
    }
  };

  if (state.succeeded) {
    return <p className="text-3xl font-bold mb-8">Agradecemos o interesse!</p>;
  }

  return (
    <form onSubmit={handleSubmit} id="form">
      <div className="px-2">
        <div className="container mx-auto">
          <p className="my-4 text-lg text-bold leading-6">
            Entre em contato e receba as informações no seu WhatsApp.
          </p>
          <input type="hidden" id="formId" name="formId" value={pageUrl} />
          <div>
            <div className="sm:col-span-4">
              <label
                htmlFor="name"
                className="block text-md font-medium leading-6 text-foreground my-3"
              >
                Nome
              </label>
              <div>
                <div className="rounded-none shadow-sm">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Nome Completo"
                    className="block w-full rounded-none border-0 py-1.5 pl-4 text-foreground shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="sm:col-span-4 mt-1">
              <div>
                <label
                  htmlFor="email"
                  className="block text-md font-medium leading-6 text-foreground my-3"
                >
                  Email
                </label>
                <div>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="seuemail@provedor.com.br"
                    className="block w-full rounded-none border-0 py-1.5 pl-4 text-foreground my-3 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
            </div>

            <div className="sm:col-span-4">
              <label
                htmlFor="phone-number"
                className="block text-md font-medium leading-6 text-foreground my-3"
              >
                Telefone
              </label>
              <div className="relative rounded-none shadow-sm">
                <div className="absolute inset-y-0 left-0 flex items-center">
                  <label htmlFor="country" className="sr-only">
                    Country
                  </label>
                  <select
                    id="country"
                    name="country"
                    autoComplete="country"
                    value={selectedCountryCode.value}
                    onChange={(e) =>
                      handleCountryCodeChange(
                        countryCodes.find(
                          (code) => code.value === e.target.value
                        )
                      )
                    }
                    className="h-full rounded-none border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                  >
                    {countryCodes.map((code) => (
                      <option key={code.value} value={code.value}>
                        {code.label}
                      </option>
                    ))}
                  </select>
                </div>
                <InputMask
                  id="phone-number"
                  name="phone-number"
                  type="text"
                  placeholder={selectedCountryCode.placeholder}
                  mask={getMask(selectedCountryCode.value)}
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  className="block w-full rounded-none border-0 py-1.5 pl-24 text-foreground my-3 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4">
            <div className="sm:col-span-4">
            <button
  type="submit"
  disabled={state.submitting}
  className={`w-full rounded-none bg-slate-900 p-3 text-md font-semibold text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 dark:bg-green-700 ${
    state.submitting ? 'cursor-not-allowed opacity-50' : ''
  }`}
  aria-label={state.submitting ? 'Enviando...' : 'Receber apresentação'}
>
  {state.submitting ? 'Enviando...' : 'Receber Apresentação'}
</button>
            </div>
          </div>
          <input type="hidden" id="formId" name="formId" value={pageUrl} />
        </div>
      </div>
    </form>
  );
}
