import React from "react";
import Image from "next/image";

function MobileCardImage({ 
  imageUrl = "/placeholder.jpg", 
  title = "Imagem do imóvel não disponível", 
  width = 300, 
  height = 200, 
  lazy = true
}) {
  // Remove the imagekit transformation parameters if they exist
  const baseUrl = imageUrl.split('?')[0];
  
  return (
    <Image
      src={baseUrl}
      alt={title}
      width={width}
      height={height}
      placeholder="blur"
      blurDataURL={`${baseUrl}?tr=w-40,q-5`}
      className="w-full h-full object-cover"
      loading={lazy ? "lazy" : "eager"}
      quality={75}
      sizes="(max-width: 640px) 100vw, (max-width: 1024px) 50vw, 33vw"
    />
  );
}
export default MobileCardImage;
